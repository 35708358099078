<template>
	<ContentContainer>
    <template #title>
      <h4 class="text-left">
        <strong>Consumer Activity - {{getConsumerDisplay}}</strong>
      </h4>
    </template>
		<div class="container">
			<b-card v-for="(timeline, i) in consumerTimelines" :key="i">
				{{getConsumerDisplay}} {{`${timeline.status}`.toLowerCase()}} 
				<router-link
					:to="{
						name: 'ClientTimelineOverview',
						params: { clientID: getClientInContext.client_id, timelineID: timeline.timeline_id },
					}"
			>
				{{timeline.workflow_name}}
			</router-link>
				on {{formatDate(timeline.datetime)}}
			</b-card>
		</div>
	</ContentContainer>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import ContentContainer from "@/components/templates/ContentContainer";

export default {
  components: {
		ContentContainer
	},
  data() {
    return {
			consumer: null,
			consumerTimelines: [],
		};
  },
  props: [],
  methods: {
		formatDate(date) {
			return moment(new Date(date)).format("MM/DD/YYYY [at] h:mm a");
		}
  },
	computed: {
		...mapGetters(["getClientInContext"]),
		getConsumerDisplay() {
			let display = "";

			if (this.consumer?.meta?.["firstName"]) {
				display = this.consumer?.meta?.["firstName"];
			}

			if (this.consumer?.meta?.["lastName"]) {
				display = `${display} ${this.consumer?.meta?.["lastName"]}`.trim();
			}

			if (display) return display;

			return this.consumer?.consumer_email;
		}
	},
	async mounted() {
		const { consumer, timelines } =
        (await this.$store.dispatch(
          "getConsumerTimelineActivity",
          this.$route.params?.consumerID
        )) || {};
		this.consumer = consumer;
		this.consumerTimelines = timelines || [];
	}
};
</script>
